import { Configuration } from '../config'
import { defaults } from './defaults'

const configuration: Configuration = {
  ...defaults,
  emailjs: {
    userId: 'user_fEge3rEtuLIilxtruCGSN',
    serviceId: 'post.alpinewhite.com',
    templateId: 'contact_form_br'
  },
  elfsight: { instagram: 'elfsight-app-33796328-445a-4686-b5c6-cbc5d86f0448' },
  shop: {
    instance: 'BR',
    sections: {
      shop: true,
      treatments: false
    },
    countries: ['BR'],
    url: 'https://br.alpinewhite.com',
    lang: { main: 'pt', enabled: ['pt'] },
    stamped: {
      apiKeyPublic: 'pubkey-324dM4yx34NN72QvfJRAg79U2dU63f',
      storeUrl: 'shop.alpinewhite.com.br'
    },
    newsletter: 'S6WLku',
    partners: ['Discover', 'DrogariaIguatemi', 'Nuspace', 'PanVel', 'Proforma', 'Glam', 'SaoJoao'],
    scripts: {
      gtm: 'GTM-P3K8685',
      hotjar: '457994',
      facebook: '2531602960492053',
      tiktok: 'C8F6G3UI9NEM6MOH09BG',
      pinterest: '2613981124848'
    },
    shopifyClient: {
      domain: 'shop-br.alpinewhite.com',
      accessToken: '07909075b5bb98971b0bbac427a59e77'
    },
    meta: {
      pt: {
        title: 'Alpine White | Fitas Clareadoras Dentais | Carvão Clareador | Clareamento',
        description:
          'Descubra a gama de produtos Alpine White. Limpeza, cuidado e clareamento caseiro para um hálito fresco, gengivas saudáveis e dentes brancos.',
        keywords: [
          'Fitas Clareadoras Dentais',
          'Kit Clareador Dental',
          'Clareamento',
          'Limpeza',
          'Cuidado',
          'Espuma Clareadora Dental',
          'Carvão Clareador Dental Em Po'
        ]
      },
      en: {
        title: 'Alpine White | Teeth Whitening Strips | Whitestrips | Bleaching',
        description:
          'Give yourself a radiant smile with white teeth. Alpine White provides a complete kit for cleaning, bleaching and care. Discover our Whitening Strips.',
        keywords: [
          'Whitening Strips',
          'Whitestrips',
          'Bleaching',
          'Homebleaching',
          'Whitening Kit',
          'Dental Care',
          'White Teeth',
          'Charcoal Powder',
          'Whitening Foam'
        ]
      }
    }
  }
}

export { configuration }
