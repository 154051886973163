import { Script } from 'gatsby'
import React, { useEffect } from 'react'

import Config from '../../config/index'
import { register as addToCart } from '../../events/addToCart'
import { register as initiateCheckout } from '../../events/initiateCheckout'
import { register as newsletterSignup } from '../../events/newsletterSignup'
import { register as pageView } from '../../events/pageView'
import { register as viewProduct } from '../../events/viewProduct'
import { register as viewTreatment } from '../../events/viewTreatment'
import { register as viewTreatmentAddon } from '../../events/viewTreatmentAddon'
import { useGDPR } from '../../provider/gdpr'

// https://developers.pinterest.com/docs/tags/pinterest-conversion-tag/
type PinterestTrack = typeof window.pintrk

const _trackWithIndex = (index: number, args: Parameters<PinterestTrack>): unknown => {
  if (typeof window.pintrk !== 'function') {
    if (index > 10) {
      return console.error(`🚨 → 🎯 → 🇵 → %c${args[0]}`, 'font-style: italic', args.slice(1))
    }

    console.warn(`⌛ (${index * 50}ms) → 🎯 → 🇵 → %c${args[0]}`, 'font-style: italic', args.slice(1))
    return setTimeout(() => _trackWithIndex(index + 1, args), index * 50)
  }

  console.info(`🎯 → 🇵 → %c${args[0]}`, 'font-style: italic', args.slice(1))

  return window.pintrk(...args)
}

const track = (...args: Parameters<PinterestTrack>) => {
  _trackWithIndex(1, args)
}

const Pinterest: React.FC = () => {
  const consent = useGDPR()
  const id = Config().shop.scripts.pinterest

  useEffect(() => {
    pageView(() => {
      track('track', 'PageVisit', {})
    })

    newsletterSignup(() => track('track', 'signup', { lead_type: 'Newsletter' }))

    viewProduct((e) => {
      const {
        detail: { category, name, id, value, currency }
      } = e

      track('track', 'pagevisit', {
        product_category: category,
        product_name: name,
        product_id: `${id}`,
        currency,
        value
      })
    })

    viewTreatment((e) => {
      const {
        detail: { category, name, id, value, currency }
      } = e
      track('track', 'pagevisit', {
        product_category: category,
        product_name: name,
        product_id: `${id}`,
        currency,
        value
      })
    })

    viewTreatmentAddon((e) => {
      const {
        detail: { category, name, id, value, currency }
      } = e
      track('track', 'pagevisit', {
        product_category: category,
        product_name: name,
        product_id: `${id}`,
        currency,
        value
      })
    })

    addToCart((e) => {
      const {
        detail: { items, currency }
      } = e
      items.forEach((item) => {
        track('track', 'addtocart', {
          product_category: item.category,
          product_name: item.name,
          product_id: `${item.id}`,
          quantity: item.quantity,
          currency,
          value: item.price * item.quantity
        })
      })
    })

    initiateCheckout((e) => {
      const {
        detail: { quantity, value, currency }
      } = e

      track('track', 'checkout', {
        order_quantity: quantity,
        currency,
        value
      })
    })
  }, [])

  if (!id || !consent?.statistic) return null

  // source: https://developers.pinterest.com/docs/tag/conversion/
  return (
    <Script strategy="idle" id="pinterest-config">
      {`
        !function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(
          Array.prototype.slice.call(arguments))};var
          n=window.pintrk;n.queue=[],n.version="3.0";var
          t=document.createElement("script");t.async=!0,t.src=e;var
          r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
        pintrk('load', '${id}');
        pintrk('page');
        `}
    </Script>
  )
}

export default Pinterest
